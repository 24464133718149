import { useMemo, useState, useEffect, useCallback } from 'react';
import { PageRole, PageType, canAddEvaluationForPage } from '@deepstream/common/rfq-utils';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from 'rebass/styled-components';
import { map, compact, partition, first } from 'lodash';
import { useField } from 'formik';
import {
  RoleRestrictionProfile,
  translateToEvaluationPageOwnerRole,
  translateToEvaluationPageTeamMemberRole,
} from '@deepstream/common/user-utils';
import { immutableSet } from '@deepstream/utils';
import { CellProps } from 'react-table';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Clamp } from '@deepstream/ui-kit/elements/text/Clamp';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { InlineButton } from '@deepstream/ui-kit/elements/button/InlineButton';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { DropdownMenuDivider } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import { PagePermissionTableStyles } from '../../../TableStyles';
import { Table } from '../../../Table';
import { RequestRole, RequestTeamUser } from '../../../types';
import { LabelConfig, LabelConfigProvider } from '../../../LabelConfigProvider';
import { Label } from '../../../ui/Label';
import {
  getItemLabelWithDescription,
  getItemLabelWithIcon,
  getItemLabelWithIconAndDescription,
  getItemValue,
  SelectField,
} from '../../../form/SelectField';
import { Select2 } from '../../../ui/Select';
import * as rfx from '../../../rfx';
import { useCurrentUser } from '../../../useCurrentUser';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useIsAppAdmin } from '../../../useIsAppAdmin';
import { HelpCenterInlineLink } from '../../../HelpCenterLink';
import { useRequestRoleSelectItems } from './useRequestRoleSelectItems';
import { PermissionRow } from './usePermissionRows';
import { Bold } from '../../../Bold';
import {
  getSelectPageRoleConfig,
} from './selectPageRoleConfig';

export const HIDDEN_ITEM_VALUE = '__hidden__';

const usePagePermissionOptions = ({
  pageType,
  isSender,
  isOwner,
  isAuthorizedStakeholderSender,
}: {
  pageType?: PageType,
  isSender: boolean;
  isOwner: boolean;
  isAuthorizedStakeholderSender: boolean;
}) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const {
      labelKeyPrefix,
      descriptionKeyPrefix,
      items,
    } = getSelectPageRoleConfig(pageType, isSender, isOwner);

    return items.map(item => ({
      value: item.pageRole,
      icon: item.icon,
      description: t(`${descriptionKeyPrefix}.${item.pageRole}`),
      // prevent setting EDITOR role for authorized stakeholders
      disabled: isAuthorizedStakeholderSender && item.pageRole === PageRole.EDITOR,
      label: t(`${labelKeyPrefix}.${item.pageRole}`),
    }));
  }, [pageType, isSender, isOwner, t, isAuthorizedStakeholderSender]);
};

type PermissionCellProps = CellProps<PermissionRow> & {
  column: {
    label: string;
    isSender: boolean;
    isOwner: boolean;
    isAuthorizedStakeholderSender: boolean;
    disabled: boolean;
  }
};

// This is using a Select2 component to match the styling of the SelectFields
// in the PagePermissionCell; functionality-wise, it's actually closer to a menu
// so we need to make some adjustments.
const PermissionColumnHeader = ({ column }: PermissionCellProps) => {
  const theme = useTheme();

  const { label, isSender, isOwner, isAuthorizedStakeholderSender, id: columnId } = column;

  const { t } = useTranslation();
  const [{ value: permissionRows },, formik] = useField<any[]>('permissions');
  const [selectedItemValue, setSelectedItemValue] = useState(HIDDEN_ITEM_VALUE);
  const options = usePagePermissionOptions({
    pageType: columnId === 'linkedEvaluationPage'
      ? PageType.EVALUATION
      : undefined,
    isSender,
    isOwner,
    isAuthorizedStakeholderSender,
  });

  useEffect(
    () => {
      setSelectedItemValue(HIDDEN_ITEM_VALUE);
    },
    [permissionRows],
  );

  const hasItems = useMemo(() => {
    return (
      columnId !== 'linkedEvaluationPage' ||
      permissionRows.some(permission => (
        permission.linkedEvaluationPage &&
        permission.page.role !== PageRole.NONE
      ))
    );
  }, [columnId, permissionRows]);

  const setValue = (pageRole: PageRole) => {
    formik.setValue(map(
      permissionRows,
      (permissionRow) => {
        // when the user has PageRole.NONE in the linked generic page then
        // the evaluation page permissions can't get updated
        if (columnId === 'linkedEvaluationPage' && permissionRow.page.role === PageRole.NONE) {
          return permissionRow;
        }

        // exclude undefined pages and auction pages from 'change all' updates
        if (!permissionRow[columnId] || permissionRow[columnId].type === PageType.AUCTION) {
          return permissionRow;
        } else {
          const updatedPermission = immutableSet(permissionRow, `${columnId}.role`, pageRole);

          if (permissionRow.linkedEvaluationPage && columnId !== 'linkedEvaluationPage') {
            return adjustEvaluationPageRole(updatedPermission, isOwner);
          } else {
            return updatedPermission;
          }
        }
      },
    ));
  };

  const items = useMemo(
    () => [
      ...options,
      // Hack: Any previously selected role in the list can't be selected again (ie. the `onChange`
      // callback won't be called from the `Select2` component), so we're adding a hidden item that
      // will be used for resetting the `selectedItem` every time the permissions change (eg when the permissions
      // for an individual page are changed).
      {
        value: HIDDEN_ITEM_VALUE,
        hidden: true,
      },
    ],
    [options],
  );

  return (
    <Box>
      <Box mb={2}>
        {label}
      </Box>
      <Select2
        selectedItem={selectedItemValue}
        onChange={({ selectedItem }) => {
          if (selectedItem.value !== HIDDEN_ITEM_VALUE) {
            setValue(selectedItem.value);
          }
        }}
        items={items}
        disabled={column.disabled || !hasItems}
        itemToString={getItemValue}
        small
        buttonStyle={{ fontWeight: 500, width: '160px', background: theme.colors.disabledBackground }}
        getButtonLabel={() => t('teamManagement.changeAll')}
        getItemLabel={getItemLabelWithIconAndDescription}
        placement="bottom-end"
        menuWidth={220}
        footer={columnId === 'linkedEvaluationPage' ? (
          <EvaluationPageRoleSelectFooter />
        ) : (
          undefined
        )}
      />
    </Box>
  );
};

const PagePermissionCell = ({ row, column }: PermissionCellProps) => {
  const { t } = useTranslation();

  const { isSender, isOwner, isAuthorizedStakeholderSender, id: columnId } = column;

  const [{ value: permissionsRows },, formik] = useField<Array<PermissionRow>>('permissions');

  const pageType = row.original[columnId]?.type;

  const options = usePagePermissionOptions({
    // legacy evaluation pages are listed in the 'page' column. In
    // terms of permissions, we handle them like generic pages,
    // not like the new evaluation pages
    pageType: columnId === 'page' && pageType === PageType.EVALUATION
      ? undefined
      : pageType,
    isAuthorizedStakeholderSender,
    isSender,
    isOwner,
  });

  // We can't rely on `row.index` because the table rows don't correspond to the `permissions`
  // (eg when there is an auction page)
  const permissionIndex = permissionsRows.findIndex(
    permission => permission.page.pageId === row.original._id,
  );

  const handlePageRoleChange = useCallback((newPageRole) => {
    formik.setValue(map(
      permissionsRows,
      (permissionRow, index) => {
        if (index !== permissionIndex) {
          return permissionRow;
        }

        const updatedPermission = immutableSet(permissionRow, `${columnId}.role`, newPageRole);

        if (permissionRow.linkedEvaluationPage && columnId !== 'linkedEvaluationPage') {
          return adjustEvaluationPageRole(updatedPermission, isOwner);
        } else {
          return updatedPermission;
        }
      },
    ));
  }, [formik, permissionsRows, permissionIndex, isOwner, columnId]);

  const isDisabled = column.disabled || (
    columnId === 'linkedEvaluationPage' &&
    permissionsRows[permissionIndex].page.role === PageRole.NONE
  );

  return row.original[columnId] ? (
    <SelectField
      name={`permissions[${permissionIndex}].${columnId}.role`}
      items={options}
      disabled={isDisabled}
      buttonStyle={{
        width: '180px',
        borderRadius: 0,
        height: '40px',
        borderColor: 'transparent',
        borderTop: isDisabled ? 0 : 'lightGray2',
        borderBottom: isDisabled ? 0 : 'lightGray2',
        borderLeft: isDisabled ? 0 : 'lightGray2',
        borderRight: isDisabled ? 'subtext' : 'lightGray2',
      }}
      getButtonLabel={getItemLabelWithIcon}
      getItemLabel={getItemLabelWithIconAndDescription}
      placement="bottom-end"
      menuWidth={220}
      onChange={handlePageRoleChange}
      footer={columnId === 'linkedEvaluationPage' ? (
        <EvaluationPageRoleSelectFooter />
      ) : (
        undefined
      )}
    />
  ) : (
    <Box px="14px" py="11px" backgroundColor="disabledBackground" color="disabledText" width="180px" height="40px">
      {(
        columnId !== 'page' &&
        row.original.page &&
        canAddEvaluationForPage(row.original.page)
      ) ? (
        t('request.team.noEvaluation')
      ) : (
        t('general.notAvailable')
      )}
    </Box>
  );
};

const RequestRoleSelectFooter = () => {
  const theme = useTheme();

  return (
    <Box pt={1}>
      <DropdownMenuDivider />
      <Text fontSize={0} color="subtext" padding="10px 10px 7px">
        <Trans i18nKey="request.team.requestRoleSelectFooter">
          {' '}
          <HelpCenterInlineLink
            // TODO collect paths like this in a single file!
            path="/en/articles/6337681-understand-the-different-types-of-user-profiles-available"
            sx={{
              // This is only needed for overwriting angular styles
              color: `${theme.colors.primary} !important`,
              textDecoration: 'none',
            }}
          >
            Learn more
          </HelpCenterInlineLink> about request user roles.
        </Trans>
      </Text>
    </Box>
  );
};

const EvaluationPageRoleSelectFooter = () => {
  return (
    <Box pt={1}>
      <DropdownMenuDivider />
      <Text fontSize={0} color="subtext" padding="10px 10px 7px">
        <Icon icon="info-circle" mr={2} />
        <Trans i18nKey="request.team.evaluationPageRoleSelectFooter">
          {' '}
          <Bold />
          {' '}
        </Trans>
      </Text>
    </Box>
  );
};

const adjustEvaluationPageRole = (permissionRow: PermissionRow, isOwner: boolean) => {
  const pageRole = permissionRow.page.role;

  if (!permissionRow.linkedEvaluationPage) {
    return permissionRow;
  }

  // when the user has no access (which implies that the user is not an owner),
  // we need to limit the evaluation page role
  if (pageRole === PageRole.NONE) {
    return immutableSet(permissionRow, 'linkedEvaluationPage.role', PageRole.NONE);
  }

  const evaluationPageRole = permissionRow.linkedEvaluationPage.role;

  const newEvaluationPageRole = isOwner
    ? translateToEvaluationPageOwnerRole(evaluationPageRole)
    : translateToEvaluationPageTeamMemberRole(evaluationPageRole);

  return immutableSet(permissionRow, 'linkedEvaluationPage.role', newEvaluationPageRole);
};

export const RequestTeamRolePermissionFields = ({
  initialPermissionRows,
  initialRequestRole,
  companyId,
  isAddUser,
}: {
  initialPermissionRows: PermissionRow[];
  initialRequestRole: RequestRole;
  companyId: string;
  isAddUser?: boolean;
}) => {
  const { t } = useTranslation();
  const [{ value: requestRole },, requestRoleHelpers] = useField<RequestRole>('requestRole');
  const [{ value: permissionRows },, permissionsHelpers] = useField<any[]>('permissions');
  const [{ value: selectedUser }] = useField<RequestTeamUser>('selectedUser');

  const { senders, teamById } = rfx.useStructure();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const companyTeamNavigation = rfx.useCompanyTeamNavigation();
  const currentUser = useCurrentUser();
  const isAdmin = useIsAppAdmin();

  const isSender = senders.some(sender => sender._id === companyId);
  const isAuthorizedStakeholderSender = (
    selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER &&
    isSender
  );
  const isSuperUser = selectedUser?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER;

  const canEditCompanyUsers = (currentUser as any).companyRoles?.find(({ _id }) => _id === currentCompanyId)?.admin;

  const [
    auctionPermissionRows,
    nonAuctionPermissionRows,
  ] = useMemo(() => partition(
    initialPermissionRows,
    permissionRow => permissionRow.page.type === PageType.AUCTION,
  ), [initialPermissionRows]);

  const auctionPermissionRow = first(auctionPermissionRows);
  const canEditAllNonAuctionPages = nonAuctionPermissionRows.every(
    permissionRow => permissionRow.page.role === PageRole.EDITOR,
  );
  const mostPrivilegedAuctionRole = isSender
    ? PageRole.EDITOR
    : PageRole.RESPONDER;
  const hasMostPrivilegedAuctionRole = auctionPermissionRow?.page.role === mostPrivilegedAuctionRole;
  const hasMostPrivilegedRoles = auctionPermissionRow
    ? canEditAllNonAuctionPages && hasMostPrivilegedAuctionRole
    : canEditAllNonAuctionPages;
  const requestRoleSelectItems = useRequestRoleSelectItems();

  const isOwner = Boolean(requestRole === RequestRole.OWNER);

  // NB Having the owner column editable when the owner doesn't have the
  // expected most privileged page roles seems to be necessary to support
  // older requests in which owner roles were editable.
  const isGenericPageColumnEditable = (
    !isOwner ||
    (!isAddUser && initialRequestRole === RequestRole.OWNER && !hasMostPrivilegedRoles)
  );

  const hasEvaluationPageColumn = initialPermissionRows.some(
    permissionRows => permissionRows.linkedEvaluationPage,
  );

  const columns = useMemo(() => compact([
    {
      Header: t('request.page', { count: 1 }),
      Cell: ({ cell }) => (
        <Text width="100%">
          <Clamp lines={2}>
            {cell.value}
          </Clamp>
        </Text>
      ),
      accessor: 'name',
      verticalAlign: 'middle',
    },
    {
      id: 'page',
      Header: PermissionColumnHeader,
      label: t('general.details'),
      Cell: PagePermissionCell,
      width: 180,
      disabled: !isGenericPageColumnEditable,
      isSender,
      isOwner,
      isAuthorizedStakeholderSender,
    },
    hasEvaluationPageColumn
      ? {
        id: 'linkedEvaluationPage',
        Header: PermissionColumnHeader,
        label: t('request.evaluation.evaluation'),
        Cell: PagePermissionCell,
        width: 180,
        disabled: false,
        isSender,
        isOwner,
        isAuthorizedStakeholderSender,
      }
      : null,
  ]), [t, isGenericPageColumnEditable, isSender, isOwner, isAuthorizedStakeholderSender, hasEvaluationPageColumn]);

  // adjust request role and permissions when selected user is an authorized stakeholder
  useWatchValue(
    selectedUser,
    useCallback(user => {
      if (user?.roleRestrictionProfile === RoleRestrictionProfile.SUPER_USER) {
        requestRoleHelpers.setValue(RequestRole.OWNER);
      } else if (user?.roleRestrictionProfile === RoleRestrictionProfile.AUTHORIZED_STAKEHOLDER) {
        requestRoleHelpers.setValue(RequestRole.TEAM_MEMBER);
        permissionsHelpers.setValue(map(
          permissionRows,
          (permissionRow) => {
            if (permissionRow.page.role !== PageRole.EDITOR) {
              return permissionRow;
            }

            const newPageRole = permissionRow.page.type === PageType.AUCTION
              ? PageRole.READER
              : PageRole.RESPONDER;

            return immutableSet(permissionRow, 'page.role', newPageRole);
          },
        ));
      }
    }, [permissionRows, permissionsHelpers, requestRoleHelpers]),
  );

  // adjust page permissions when request role (team member / owner) changes
  useWatchValue(
    requestRole,
    useCallback((newRequestRole) => {
      permissionsHelpers.setValue(map(
        permissionRows,
        (permissionRow) => {
          const isOwner = newRequestRole === RequestRole.OWNER;

          if (!isOwner) {
            // when switching from owner to team member, set all page permissions
            // to PageRole.NONE
            const permissionWithUpdatedPageRole = immutableSet(permissionRow, 'page.role', PageRole.NONE);
            return adjustEvaluationPageRole(permissionWithUpdatedPageRole, isOwner);
          } else {
            // when switching from team member to owner, set the most privileged
            // page roles
            const newPageRole = permissionRow.type === PageType.AUCTION
              ? mostPrivilegedAuctionRole
              : PageRole.EDITOR;

            const permissionWithUpdatedPageRole = immutableSet(permissionRow, 'page.role', newPageRole);
            return adjustEvaluationPageRole(permissionWithUpdatedPageRole, isOwner);
          }
        },
      ));
    }, [permissionsHelpers, permissionRows, mostPrivilegedAuctionRole]),
  );

  return (
    <>
      <LabelConfigProvider variant={LabelConfig.ABOVE}>
        <SelectField
          name="requestRole"
          label={t('general.role')}
          items={requestRoleSelectItems}
          disabled={isSuperUser || isAuthorizedStakeholderSender || (
            selectedUser?._id === currentUser._id &&
            selectedUser?.companyId === currentCompanyId
          ) || (
            !isAddUser &&
            teamById[selectedUser?.companyId]?.owners.length === 1 &&
            teamById[selectedUser?.companyId]?.owners[0] === selectedUser?._id
          )}
          getButtonLabel={item => item?.label ?? ''}
          getItemLabel={getItemLabelWithDescription}
          footer={<RequestRoleSelectFooter />}
        />
        {isSuperUser && (
          <Text color="subtext" fontSize={0} mt={-2}>
            <Icon
              style={{ fontSize: '12px', marginRight: 4 }}
              icon="lock"
              light
            />
            {t('teamManagement.cannotBeEditedForSuperUser')}
          </Text>
        )}
      </LabelConfigProvider>

      {isAuthorizedStakeholderSender && (
        <MessageBlock variant="info" maxWidth="600px" mt={0}>
          {isAdmin ? (
            t('teamManagement.authorizedStakeholdersHaveLimitedPermissions')
          ) : (
            t('teamManagement.authorizedStakeholdersAtCompanyHaveLimitedPermissions')
          )}
          {!isAdmin && canEditCompanyUsers && (
            <>
              {' '}
              <InlineButton
                type="button"
                onClick={companyTeamNavigation?.navigateToCompanyTeam}
              >
                {t('request.team.openTeamManagement')}
              </InlineButton>
            </>
          )}
        </MessageBlock>
      )}

      <Box>
        <Label label={t('teamManagement.permission_other')} color="lightNavy" />
        {(
          !isGenericPageColumnEditable &&
          !hasEvaluationPageColumn
        ) ? (
          <Box width="600px">
            <MessageBlock variant="info" mt={0}>
              {t('request.team.ownersHaveFullAccess')}
            </MessageBlock>
          </Box>
        ) : (
          <>
            <Panel maxWidth="600px">
              <PagePermissionTableStyles px="10px" largeHeader>
                <Table columns={columns} data={nonAuctionPermissionRows} />
              </PagePermissionTableStyles>
            </Panel>
            {auctionPermissionRow ? (
              <Panel mt={3} maxWidth="600px">
                <PagePermissionTableStyles px="10px">
                  <Table columns={columns} data={auctionPermissionRows} hideHeader />
                </PagePermissionTableStyles>
              </Panel>
            ) : (
              null
            )}
            {isOwner && hasEvaluationPageColumn ? (
              <Box width="600px" mt={3}>
                <MessageBlock variant="info" mt={0}>
                  <Trans i18nKey="request.team.ownersHaveFullEditAccessAndCanSeeEvaluation">
                    {' '}
                    <Icon icon="pencil" fontSize={1} />
                    <Bold />
                    {' '}
                  </Trans>
                </MessageBlock>
              </Box>
            ) : (
              null
            )}
          </>
        )}
      </Box>
    </>
  );
};
