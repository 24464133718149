import { createContext, useContext } from 'react';
import { ExchangeDefinition, Section, RfxSection, PagePermissions, RfxSummary, RfqStatus } from '@deepstream/common/rfq-utils';
import { ContractExchangeDefinition, ContractSection, ContractStatus, ContractSummary } from '@deepstream/common/contract';
import { BidProgress } from '@deepstream/common/bidProgress';
import { QuestionnaireStatus, QuestionnaireTemplateStatus } from '@deepstream/common/preQual';
import { Auction, ExchangeSnapshot } from './types';
import { useESign } from './modules/Contracts/useESign';
import { useUpdateBulletin } from './modules/RequestMessages/useUpdateBulletin';

export enum ContextType {
  RFX = 'rfx',
  CONTRACT = 'contract',
  QUESTIONNAIRE = 'questionnaire',
  QUESTIONNAIRE_TEMPLATE = 'questionnaireTemplate',
}

type Hooks = {
  contextType: ContextType;
  useStatus: () => RfqStatus | ContractStatus | QuestionnaireStatus | QuestionnaireTemplateStatus;
  useContextId: () => string; // Can be rfxId, contractId, questionnaireId etc.
  useShowValidationErrors: () => boolean;
  useSummary: () => Partial<RfxSummary> & { isLive?: boolean } | ContractSummary;
  useExchangeDefById: () => Record<string, ExchangeDefinition | ContractExchangeDefinition>;
  useExchanges: (options?: { required: boolean }) => ExchangeSnapshot[];
  useSection: <TSection = Section | RfxSection | ContractSection>() => TSection;
  useAuction: () => Auction | null;
  usePagePermissions: () => PagePermissions;
  useBidProgress: () => BidProgress | null;
  useCommentNotifications: (exchange: ExchangeSnapshot) => any[];
  useRecipients: () => any[];
  useAvailableBulletins: (exchange: ExchangeSnapshot) => any[];
  useUpdateBulletin: typeof useUpdateBulletin | (() => null);
  useActions: () => {
    startEditing: (sectionId: string) => void;
    stopEditing: () => void;
    decideCanLeavePage?: (decision: boolean) => void;
  };
  useESign: typeof useESign | (() => null);
  useIsSuperUserOrOwner: () => boolean;
};

export const HooksContext = createContext<Hooks>(null);

export const useHooks = () => {
  const hooks = useContext(HooksContext);
  if (!hooks) throw new Error('No hooks provider was found');
  return hooks;
};
