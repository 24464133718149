import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import { MessagesSectionType } from '@deepstream/common/rfq-utils';
import { AuctionTabId } from './draft/AuctionTabId';

export const RequestEditNavigationContext = createContext<{
  navigateToSummary:() => void;
  navigateToStages: () => void;
  navigateToDetailsIndex: () => void;
  navigateToDetailsPage: (pageId: string) => void;
  navigateToAuction: (tab?: AuctionTabId, replace?: boolean) => void;
  navigateToEvaluationIndex: () => void;
  navigateToEvaluationPage: (pageId: string) => void;
  navigateToSpend: () => void;
  navigateToTeam: (companyId?: string, replace?: boolean) => void;
  navigateToSuppliers: () => void;
  navigateToReview: () => void;
  navigateToApprovals: () => void;
  navigateToComments: () => void;
}>({
  navigateToSummary: noop,
  navigateToStages: noop,
  navigateToDetailsIndex: noop,
  navigateToDetailsPage: noop,
  navigateToAuction: noop,
  navigateToEvaluationIndex: noop,
  navigateToEvaluationPage: noop,
  navigateToSpend: noop,
  navigateToTeam: noop,
  navigateToSuppliers: noop,
  navigateToReview: noop,
  navigateToApprovals: noop,
  navigateToComments: noop,
});

export const useRequestEditNavigation = () => {
  return useContext(RequestEditNavigationContext);
};

export const RequestSentNavigationContext = createContext<{
  getSuppliersLinkProps:(params?: { stageId?: string; exchangeId?: string }) => any;
  navigateToSuppliers:(params?: { stageId?: string; exchangeId?: string }) => void;
  navigateToAddSuppliers: () => void;
  navigateToTeam: (params?: { companyId?: string }, replace?: boolean) => void;
  navigateToDetails: () => void;
  navigateToLegacyBulletins: () => void;
  navigateToAuction: () => void;
  navigateToMessages: (sectionType?: MessagesSectionType, replace?: boolean) => void;
  navigateToComments: () => void;
  navigateToComparison: () => void;
  navigateToSpend: () => void;
  navigateToAudit: () => void;
  navigateToVesselPricing: () => void;
  navigateToAward: () => void;
  navigateToAwardFlow: () => void;
  navigateToSupplierBid: (pageId?: string, exchangeId?: string) => void;
  navigateToSupplierBidEvaluation: (pageId?: string, exchangeId?: string) => void;
  navigateToSupplierTeam: () => void;
  navigateToSupplierAudit: () => void;
}>({
  getSuppliersLinkProps: noop,
  navigateToSuppliers: noop,
  navigateToAddSuppliers: noop,
  navigateToTeam: noop,
  navigateToDetails: noop,
  navigateToLegacyBulletins: noop,
  navigateToAuction: noop,
  navigateToMessages: noop,
  navigateToComments: noop,
  navigateToComparison: noop,
  navigateToSpend: noop,
  navigateToAudit: noop,
  navigateToVesselPricing: noop,
  navigateToAward: noop,
  navigateToAwardFlow: noop,
  navigateToSupplierBid: noop,
  navigateToSupplierBidEvaluation: noop,
  navigateToSupplierTeam: noop,
  navigateToSupplierAudit: noop,
});

export const useRequestSentNavigation = () => {
  return useContext(RequestSentNavigationContext);
};

export const RequestRecipientNavigationContext = createContext<{
  getBidLinkProps:(
    stageId?: string | null,
    requirementGroupId?: string | null,
    pageId?: string,
    exchangeId?: string,
    replace?: boolean,
  ) => any;
  getAwardSummaryLinkProps: () => any;
  getEvaluationLinkProps:(pageId?: string, exchangeId?: string) => any;
  getMessagesLinkProps: (sectionId?: string) => any;
  getTeamLinkProps: (companyId?: string, replace?: boolean) => any;
  getLegacyBulletinsLinkProps: () => any;
  getAuditLinkProps: () => any;

  navigateToBid:(
    stageId?: string | null,
    requirementGroupId?: string | null,
    pageId?: string,
    exchangeId?: string,
    replace?: boolean,
  ) => void;
  navigateToEvaluation:(pageId?: string, exchangeId?: string) => void;
  navigateToMessages: (sectionId?: string) => void;
  navigateToDetails: () => void;
  navigateToAuction: () => void;
  navigateToTeam: (companyId?: string, replace?: boolean) => void;
  navigateToLegacyBulletins: () => void;
  navigateToAudit: () => void;
  navigateToAward: () => void;
}>({
  getBidLinkProps: noop,
  getAwardSummaryLinkProps: noop,
  getEvaluationLinkProps: noop,
  getMessagesLinkProps: noop,
  getTeamLinkProps: noop,
  getLegacyBulletinsLinkProps: noop,
  getAuditLinkProps: noop,
  navigateToBid: noop,
  navigateToEvaluation: noop,
  navigateToMessages: noop,
  navigateToDetails: noop,
  navigateToAuction: noop,
  navigateToTeam: noop,
  navigateToLegacyBulletins: noop,
  navigateToAudit: noop,
  navigateToAward: noop,
});

export const useRequestRecipientNavigation = () => {
  return useContext(RequestRecipientNavigationContext);
};

export const DraftContractNavigationContext = createContext<{
  navigateToSummary:() => void;
  navigateToDetailsIndex: () => void;
  navigateToDetailsPage: (pageId: string) => void;
  navigateToContract: (tab?: string, replace?: boolean) => void;
  navigateToTeam: (companyId?: string, replace?: boolean) => void;
  navigateToReminders: () => void;
  navigateToReview: () => void;
}>({
  navigateToSummary: noop,
  navigateToDetailsIndex: noop,
  navigateToDetailsPage: noop,
  navigateToContract: noop,
  navigateToTeam: noop,
  navigateToReminders: noop,
  navigateToReview: noop,
});

export const useDraftContractNavigation = () => {
  return useContext(DraftContractNavigationContext);
};

export const LiveContractNavigationContext = createContext<{
  navigateToSummary:() => void;
  navigateToDetailsIndex: () => void;
  navigateToDetailsPage: (pageId: string, search?: Record<string, unknown>) => void;
  navigateToContract: (search?: Record<string, unknown>) => void;
  navigateToTeam: (companyId?: string, replace?: boolean) => void;
  navigateToReminders: () => void;
  navigateToAudit: () => void;
}>({
  navigateToSummary: noop,
  navigateToDetailsIndex: noop,
  navigateToDetailsPage: noop,
  navigateToContract: noop,
  navigateToTeam: noop,
  navigateToReminders: noop,
  navigateToAudit: noop,
});

export const useLiveContractNavigation = () => {
  return useContext(LiveContractNavigationContext);
};

export const DraftQuestionnaireTemplateNavigationContext = createContext<{
  navigateToSummary:(templateId?: string) => void;
  navigateToQuestions:(templateId?: string) => void;
  navigateToReview:(templateId?: string) => void;
}>({
  navigateToSummary: noop,
  navigateToQuestions: noop,
  navigateToReview: noop,
});

export const useDraftQuestionnaireTemplateNavigation = () => {
  return useContext(DraftQuestionnaireTemplateNavigationContext);
};

export const ActiveQuestionnaireTemplateNavigationContext = createContext<{
  navigateToSuppliers:(templateId?: string) => void;
  navigateToQuestions:(templateId?: string) => void;
  navigateToDetails:(templateId?: string) => void;
}>({
  navigateToSuppliers: noop,
  navigateToQuestions: noop,
  navigateToDetails: noop,
});

export const useActiveQuestionnaireTemplateNavigation = () => {
  return useContext(ActiveQuestionnaireTemplateNavigationContext);
};

export const QuestionnaireNavigationContext = createContext<{
  navigateToDetails:(questionnaireId?: string) => void;
  navigateToQuestions:(questionnaireId?: string, query?: Record<string, string>) => void;
  navigateToTeam:(questionnaireId?: string) => void;
  navigateToAudit:(questionnaireId?: string) => void;
}>({
  navigateToDetails: noop,
  navigateToQuestions: noop,
  navigateToTeam: noop,
  navigateToAudit: noop,
});

export const useQuestionnaireNavigation = () => {
  return useContext(QuestionnaireNavigationContext);
};

export const SupplierListNavigationContext = createContext<{
  navigateToLists:(search?: { from: 'externalNetwork' | 'internalNetwork' }) => void;
  navigateToListSuppliers: (supplierListId?: string) => void;
  navigateToListSettings: (supplierListId?: string) => void;
  navigateToListEdit: (supplierListId?: string) => void;
  navigateToCreateList: () => void;
  getInternalNetworkLinkProps: () => any;
  getExternalNetworkLinkProps: () => any;
  getSupplierListsLinkProps: () => any;
  getSupplierListSettingsLinkProps: (supplierListId: string) => any;
}>({
  navigateToLists: noop,
  navigateToListSuppliers: noop,
  navigateToListSettings: noop,
  navigateToListEdit: noop,
  navigateToCreateList: noop,
  getInternalNetworkLinkProps: noop,
  getExternalNetworkLinkProps: noop,
  getSupplierListsLinkProps: noop,
  getSupplierListSettingsLinkProps: noop,
});

export const useSupplierListNavigation = () => {
  return useContext(SupplierListNavigationContext);
};
