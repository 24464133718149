import * as React from 'react';
import { CellProps } from 'react-table';
import { Box } from 'rebass/styled-components';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { DateFormat } from '@deepstream/utils';
import { useDeviceSize } from './ui/useDeviceSize';
import { Datetime, DatetimeValue, getFormattedDate } from './Datetime';
import { useCurrentUserLocale } from './useCurrentUser';

export const DatetimeCell: React.FC<CellProps<{ value: DatetimeValue }>> = ({ cell: { value }, column }) => {
  const { isExtraLarge } = useDeviceSize();

  return (
    <Datetime
      isCondensed={(column as any).isCondensed ?? !isExtraLarge}
      onlyDate={(column as any).onlyDate}
      value={value}
      style={{ whiteSpace: 'nowrap' }}
    />
  );
};

export const Datetime2Cell: React.FC<
  CellProps<{ value: DatetimeValue }> &
  { column: { format?: DateFormat; EmptyCell?: React.FC<{ exchange: any; showTooltip?: boolean }>; showTooltip?: boolean } }
> = (props) => {
  const locale = useCurrentUserLocale();
  const { cell: { value }, row, column, sx } = props;

  const { format, EmptyCell, showTooltip } = column;

  const formattedValue = getFormattedDate(value, format, locale);

  return formattedValue ? (
    <Tooltip content={showTooltip && formattedValue}>
      <Box sx={sx}>{formattedValue}</Box>
    </Tooltip>
  ) : EmptyCell ? (
    <EmptyCell exchange={row?.original} showTooltip={showTooltip} />
  ) : (
    null
  );
};
