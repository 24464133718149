import { ComponentProps } from 'react';
import * as React from 'react';
import { useField } from 'formik';
import { Flex, Box, SxStyleProp } from 'rebass/styled-components';
import { isNil, noop, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IconValue } from '@deepstream/common';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { FontSizeProps } from 'styled-system';
import { Input, InputProps, InputPrefix, InputSuffix, TextArea } from '../ui/Input';
import { FormGroup } from '../ui/FormGroup';
import { FieldContainer } from './FieldContainer';
import { ErrorMessage, HelperText } from './Field';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

const CharactersRemaining = ({ value, maxLength }: { value: any; maxLength: number }) => {
  const { t } = useTranslation();

  const numCharactersRemaining = maxLength
    ? maxLength - String(value).length
    : null;

  return (
    <HelperText
      text={t('form.charactersRemaining', { numChars: numCharactersRemaining })}
      sx={{ whiteSpace: 'nowrap' }}
    />
  );
};

type TextFieldBaseProps = {
  disabled?: boolean;
  error?: string;
  format?: InputProps['format'];
  helperText?: string | React.ReactNode;
  extendedInfo?: (value: string | number) => React.ReactNode;
  hideLabel?: boolean;
  hideError?: boolean;
  inputType?: InputProps['type'];
  label?: string;
  description?: string;
  decimalPlaces?: number;
  maxLength?: number;
  maxHeight?: number;
  minHeight?: number;
  hasDynamicHeight?: boolean;
  name?: string;
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];
  onChange?: InputProps['onChange'];
  onValueChange?: InputProps['onValueChange'];
  placeholder?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  required?: boolean;
  sx?: SxStyleProp;
  value?: number | string;
  inputStyle?: React.CSSProperties;
  isMultiLine?: boolean;
  autoCapitalize?: InputProps['autoCapitalize'];
  autoFocus?: InputProps['autoFocus'];
  rows?: number;
  autoComplete?: InputProps['autoComplete'];
  inputPrefixMinWidth?: number;
  inputPrefixProps?: Partial<ComponentProps<typeof InputPrefix>>;
  maxValue?: number;
  onKeyDown?: (event: any) => void;
  infoTooltip?: React.ReactChild;
  tooltipIcon?: IconValue;
  additionalContent?: React.ReactNode;
  errorMessageStyle?: React.CSSProperties;
  descriptionStyle?: SxStyleProp;
  labelStyle?: SxStyleProp;
} & FontSizeProps;

export const TextFieldBase = React.forwardRef<HTMLInputElement, TextFieldBaseProps>(({
  disabled,
  error,
  hideError,
  format,
  helperText,
  extendedInfo,
  hideLabel,
  decimalPlaces,
  inputType = 'text',
  label,
  description,
  maxLength,
  name,
  onBlur,
  onFocus,
  onChange,
  onValueChange = noop,
  placeholder,
  prefix,
  suffix,
  required,
  sx,
  value,
  inputStyle = {},
  isMultiLine,
  autoCapitalize,
  autoComplete,
  autoFocus,
  rows,
  hasDynamicHeight,
  maxHeight,
  minHeight,
  fontSize,
  inputPrefixMinWidth,
  inputPrefixProps,
  maxValue,
  onKeyDown,
  infoTooltip,
  tooltipIcon,
  additionalContent,
  errorMessageStyle,
  descriptionStyle,
  labelStyle,
}: TextFieldBaseProps, ref) => {
  const id = useUniqueId();
  const FormGroupWrapper = additionalContent
    ? Flex
    : React.Fragment;

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      hideLabel={hideLabel}
      showAsterisk={required}
      width="100%"
      description={description}
      infoTooltip={infoTooltip}
      tooltipIcon={tooltipIcon}
      descriptionStyle={descriptionStyle}
      labelStyle={labelStyle}
    >
      {isMultiLine ? (
        <TextArea
          sx={sx}
          id={id}
          name={name}
          value={value}
          rows={!hasDynamicHeight ? rows || 4 : undefined}
          hasDynamicHeight={hasDynamicHeight}
          maxHeight={maxHeight}
          minHeight={minHeight}
          onInput={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder}
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          ref={ref}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          fontSize={fontSize}
        />
      ) : (
        <FormGroupWrapper>
          <FormGroup sx={sx}>
            {prefix && (
              <InputPrefix minWidth={inputPrefixMinWidth || 36} {...inputPrefixProps}>{prefix}</InputPrefix>
            )}
            <Input
              id={id}
              type={inputType}
              format={format}
              name={name}
              value={value}
              onChange={onChange}
              onValueChange={onValueChange}
              decimalPlaces={decimalPlaces}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              style={inputStyle}
              maxLength={maxLength}
              placeholder={placeholder}
              autoCapitalize={autoCapitalize}
              ref={ref}
              autoComplete={autoComplete}
              maxValue={maxValue}
              onKeyDown={onKeyDown}
              autoFocus={autoFocus}
              fontSize={fontSize}
            />
            {suffix && (
              <InputSuffix>{suffix}</InputSuffix>
            )}
          </FormGroup>
          {additionalContent}
        </FormGroupWrapper>
      )}
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" style={errorMessageStyle} />
        </Box>
      ) : extendedInfo ? (
        <Box sx={{ wordBreak: 'break-word', textAlign: 'left' }} mt={1}>
          {extendedInfo(value)}
        </Box>
      ) : helperText || maxLength ? (
        <Flex justifyContent="space-between">
          {helperText && (
            <HelperText text={helperText} mr={maxLength ? 2 : 0} />
          )}
          {maxLength && (
            <CharactersRemaining value={value} maxLength={maxLength} />
          )}
        </Flex>
      ) : (
        null
      )}
    </FieldContainer>
  );
});

export interface TextFieldProps extends TextFieldBaseProps {
  /**
   * Use this prop if you want the value to be bound to the form (ie: for editing)
   */
  name?: string;
  /**
   * Use this prop if you want to read a value from the form, without the possibility
   * of editing (ie: for a synced read-only field)
   */
  fieldName?: string;
  /**
   * Transforms the value *after* it has been set in the form
   */
  transformValue?: (value: any) => string | number;
  /**
   * Only works with the `format` prop
   */
  convertNonNumbersToNull?: boolean;
}

/**
 * Formik-aware text field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(({
  name,
  fieldName,
  format,
  transformValue,
  convertNonNumbersToNull,
  ...props
}: TextFieldProps, ref) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });
  const value = transformValue?.(field.value) ?? field.value;

  const onValueChange = React.useCallback(
    (value) => {
      value = convertNonNumbersToNull
        ? (isNumber(value) ? value : null)
        : value;

      formik.setValue(value);
    },
    [convertNonNumbersToNull, formik],
  );

  return (
    <TextFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      format={format}
      name={name}
      onBlur={field.onBlur}
      onFocus={props.onFocus}
      onChange={format ? undefined : field.onChange}
      onValueChange={format ? onValueChange : undefined}
      value={isNil(value) ? '' : value}
      ref={ref}
      {...props}
    />
  );
});
