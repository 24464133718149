import styled from 'styled-components';
import {
  DEFAULT_FROZEN_FOOTER_HEIGHT,
  DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT,
} from '../core/constants';
import { Color } from '../../theme/types';

export const DefaultEditableGridStyles = styled.div<{
  isReadOnly?: boolean;
  highlightOnHover?: boolean;
  hoverBackgroundColor?: Color;
  selectedHoverBackgroundColor?: Color;
  disabledHoverBackgroundColor?: Color;
  cellContentCss?: string;
  cellContentWithPrefixCss?: string;
  cellContentWithSuffixCss?: string;
  activeCellContentCss?: string;
  activeCellContentWithPrefixCss?: string;
  activeCellContentWithSuffixCss?: string;
  omitLastRowBorderBottom?: boolean;
  suffixCss?: string;
  activeSuffixCss?: string;
  disabledCellsWithPointer?: boolean;
  overrides?: string;
}>`
  --frozen-footer-height: ${DEFAULT_FROZEN_FOOTER_HEIGHT}px;
  --frozen-header-first-line-height: ${DEFAULT_FROZEN_HEADER_FIRST_LINE_HEIGHT}px;
  --frozen-header-second-line-height: 0px;
  --frozen-header-height: calc(var(--frozen-header-first-line-height) + var(--frozen-header-second-line-height));

  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.25;
  color: ${props => props.theme.colors.text};

  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 4px;
  background-color: ${props => props.theme.colors.lightGray3};

  ${props => props.omitLastRowBorderBottom ? (`
    tbody > tr:last-child [role=gridcell] {
      border-bottom: 0;
    }
  `) : ''};

  [role=treegrid] {
    width: 100%;
    height: 100%;
  }

  .frozen-left-column-wrapper {
    position: sticky;
    left: 0;
    height: calc(100% - var(--frozen-header-height));
    overflow: hidden;
    background-color: ${props => props.theme.colors.lightGray3};
    z-index: 3;
    border-right: 1px solid ${props => props.theme.colors.lightGray};
  }

  .horizontal-scroll .frozen-left-column-wrapper {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }

  .horizontal-scroll .bottom-left-cell {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -8px -8px -8px);
  }

  .frozen-header-wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
    height: var(--frozen-header-height);
    background-color: ${props => props.theme.colors.lightGray3};
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    overflow: hidden;
  }

  .frozen-footer-wrapper {
    position: sticky;
    bottom: 0;
    z-index: 4;
    height: 0;
    background-color: ${props => props.theme.colors.lightGray3};
  }

  .vertical-scroll .frozen-header-wrapper,
  .vertical-scroll .top-left-cell {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    clip-path: inset(-8px 0px -8px -8px);
  }

  .top-left-cell,
  .bottom-left-cell,
  .header-cell,
  .footer-cell,
  .right-footer-spacer,
  [role=gridcell] {
    background-color: white;
    border-right: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    overflow: hidden;
    user-select: none;
  }

  .number-cell {
    font-size: ${props => props.theme.fontSizes[1]}px;
    background-color: ${props => props.theme.colors.lightGray3};
    color: ${props => props.theme.colors.subtext};
    cursor: default;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    position: absolute;
    height: 100%;
    width: 40px;
    user-select: none;
    overflow-wrap: normal;

    ${props => props.cellContentCss || ''};
  }

  .hover .number-cell {
    background-color: ${props => props.theme.colors[props.hoverBackgroundColor || 'lightPrimary4']};
  }

  .top-left-cell-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 4;
    height: 0;
    width: 0;
  }

  .top-left-cell {
    position: relative;
    top: 0;
    height: var(--frozen-header-first-line-height);
    background-color: ${props => props.theme.colors.lightGray3};
  }

  .header-cell {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.lightGray3};
    color: ${props => props.theme.colors.lightNavy};
    font-weight: 500;
  }

  .right-footer-spacer {
    background-color: ${props => props.theme.colors.lightGray3};
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: 0;
    border-right: 0;
  }

  [role=gridcell].hover {
    .column-action {
      background-color: ${props => props.theme.colors[props.hoverBackgroundColor || 'lightPrimary4']};
    }
  }

  .column-action {
    background-color: ${props => props.theme.colors.lightGray3};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    height: 100%;

    ${props => props.cellContentCss || ''};
  }

  [role=treegrid]:focus {
    outline: none;
  }

  .cell-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    overflow: hidden;

    ${props => props.cellContentCss || ''};

    &.disabled {
      color: ${props => props.theme.colors.disabledText};
      background-color: ${props => props.theme.colors.disabledBackground};
    }

    &.with-prefix {
      ${props => props.cellContentWithPrefixCss || ''};
    }
    &.with-suffix {
      ${props => props.cellContentWithSuffixCss || ''};
    }

    &.highlighted {
      background-color: ${props => props.theme.colors.highlight};
      font-weight: 500;
    }
  }

  .prefix {
    flex: 0 0 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.subtext};
    background-color: ${props => props.theme.colors.lightGrayTransparent};
    height: 100%;
    padding: 10px;
    border-right: ${props => props.theme.borders.lightGray};
    overflow-wrap: normal;

    ${props => props.suffixCss || ''};
  }

  .post-prefix,
  .pre-suffix {
    height: 100%;
    padding: 11px 10px 0;
  }

  ${props => props.highlightOnHover ? (`
    .hover {
      background-color: ${props.theme.colors[props.hoverBackgroundColor || 'lightPrimary4']};
    }
  `) : ''}

  .hover-overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 40px;

    background-color: ${props => props.theme.colors[props.hoverBackgroundColor || 'lightPrimary4']};
  }

  .hover .hover-overlay {
    visibility: visible;
  }

  ${props => props.isReadOnly ? (`
    [role=gridcell] {
      user-select: text;
    }
  `) : (`
    .active .prefix {
      flex: 0 0 34px;
      padding-left: 8px;

      ${props.activeSuffixCss || ''};
    }
    .active .post-prefix {
      padding-top: 9px;
      padding-right: 8px;
    }
    .active .pre-suffix {
      padding-top: 9px;
      padding-left: 8px;
    }

    .active .suffix {
      flex: 0 0 34px;
      padding-right: 8px;

      ${props.activeSuffixCss || ''};
    }

    .active .column-action {
      z-index: 1;
      padding: 1px;
      border: 2px solid ${props.theme.colors.lightPrimary};
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

      ${props.activeCellContentCss || ''};
    }

    .active .cell-content {
      z-index: 1;
      padding-left: 8px;
      padding-right: 8px;
      border: 2px solid ${props.theme.colors.lightPrimary};
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

      ${props.activeCellContentCss || ''};

      &.with-prefix {
        ${props.activeCellContentWithPrefixCss || ''};
      }
      &.with-suffix {
        ${props.activeCellContentWithSuffixCss || ''};
      }
    }

    [role=treegrid]:focus .active .column-action,
    [role=treegrid]:focus .active .cell-content {
      border: 2px solid ${props.theme.colors.primary};
    }

    [role=gridcell]:hover {
      background-color: ${props => props.theme.colors[props.hoverBackgroundColor || 'lightPrimary4']};
    }

    ${props.disabledCellsWithPointer ? (`
      .cell-content {
        cursor: pointer;
      } 
    `) : (`
      .cell-content:not(.disabled) {
        cursor: pointer;
      }
    `)}

    .hover .cell-content.disabled {
      background-color: ${props.theme.colors[props.disabledHoverBackgroundColor || 'lightPrimary38']}
    }

    .selected {
      background-color: ${props.theme.colors.lightPrimary4};

      &:hover, &.hover {
        background-color: ${props.theme.colors[props.selectedHoverBackgroundColor || 'lightPrimary38']};
      }

      .cell-content.disabled {
        background-color: ${props.theme.colors.lightPrimary4Disabled};
      }

      .cell-content.highlighted {
        background-color: ${props.theme.colors.lightPrimary4Highlighted};
        font-weight: 500;
      }

      .prefix {
        background-color: ${props.theme.colors.lightPrimary4Disabled};
      }
    }
`)}

  .suffix {
    flex: 0 0 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.subtext};
    height: 100%;
    padding: 10px;
    border-left: ${props => props.theme.borders.lightGray};
    overflow-wrap: normal;

    ${props => props.suffixCss || ''};
  }

  .cell-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    outline: none;
    border: 0;

    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.lightPrimary};

    &:focus {
      box-shadow: inset 0 0 0 2px ${props => props.theme.colors.primary},
                  0 0 8px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .invalid {
    background-color: ${props => props.theme.colors.errorBackground};
  }

  .number-cell.invalid,
  .disabled.invalid {
    background-color: rgb(241,235,236);
  }

  .warning {
    background-color: ${props => props.theme.colors.warningBackground};
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.warning};
  }

  .warning.number-cell {
    box-shadow: unset;
  }

  [role=gridcell].subheader:not(.column-last) {
    border-right: 1px solid ${props => props.theme.colors.disabledBackground};
  }

  ${props => props.overrides || ''};
`;
