import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components';
import { Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { callAll } from '@deepstream/utils/callAll';
import { SaveButton, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { Panel, PanelDivider, PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import { first, flatMap, get, isEmpty, isString, pick } from 'lodash';
import { AwardScenario, ChangeType, Draft, Lot, LotChange, LotRemovedChange, SectionType, isLinkedEvaluationSection } from '@deepstream/common/rfq-utils';
import { immutableSet } from '@deepstream/utils';
import { EditableGridDataProvider, useEditableGridData } from '@deepstream/ui-kit/grid/EditableGrid/editableGridData';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { GridMenuStateProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridMenuState';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { IconValue } from '@deepstream/common';
import * as draft from './draft';
import * as rfx from '../rfx';
import { SummaryPanelHeader } from './SummaryPanelHeader';
import { DraftSectionLabelConfigProvider } from './DraftSectionLabelConfigProvider';
import { LeavePageModal } from './LeavePageModal';
import { FieldContainer } from '../form/FieldContainer';
import { SwitchFieldBase } from '../form/SwitchField';
import { LotsGrid, createLot } from '../ui/ExchangeDefsGrid/LotsGrid';
import { CheckboxField } from '../form/CheckboxField';
import { RfxStructure } from '../types';
import { useConfirmDialogWithConfig } from '../ui/useModalState';
import { Validation, useErrors } from './validation';
import { ErrorMessage } from '../ui/ErrorMessage';
import { AdditionalSummaryLotsSaveChangesDialogProps, LotReassignment, SummaryLotsSaveChangesDialog } from './SummaryLotsSaveChangesDialog';
import { isAuctionStage } from '../utils';

export const orderedAwardScenarios = [
  'requestLevelAward',
  'lineLevelAward',
  'lotLevelAward',
  'noAward',
] as const;

export const orderedAwardScenariosWithLots = [
  'lotLevelAward',
  'noAward',
] as const;

export const orderedAwardScenariosWithoutLots = [
  'requestLevelAward',
  'lineLevelAward',
  'noAward',
] as const;

const panelId = 'lotsAndAwardScenarios';

const setLotFieldValue = (lot, path, value) => {
  return immutableSet(lot, path, value);
};

export const awardScenarioIconProps: Record<string, { icon: IconValue; regular?: boolean }> = {
  requestLevelAward: {
    icon: 'file-text-o',
  },
  lotLevelAward: {
    icon: 'grid-2',
    regular: true,
  },
  lineLevelAward: {
    icon: 'list-ul',
  },
  noAward: {
    icon: 'times',
  },
};

const AwardScenarioLabelAndDescription = ({
  awardScenario,
  canSplitAwards,
  ...props
}: FlexProps & { awardScenario: keyof RfxStructure['settings']['awardScenarios'], canSplitAwards?: boolean }) => {
  const { t } = useTranslation('translation');
  const iconProps = awardScenarioIconProps[awardScenario];

  return (
    <Flex {...props}>
      <Icon {...iconProps} mr="6px" mt="2px" />
      <Box>
        <Text>
          {t(`request.awardScenarios.awardScenarios.${awardScenario}.label`)}
        </Text>
        <Text fontSize={1} color="subtext">
          {awardScenario === AwardScenario.LINE_LEVEL_AWARD && canSplitAwards ? (
            t('request.awardScenarios.awardScenarios.lineLevelAward.splitDescription')
          ) : (
            t(`request.awardScenarios.awardScenarios.${awardScenario}.description`)
          )}
        </Text>
      </Box>
    </Flex>
  );
};

const FormikLotsGrid = () => {
  const {
    rowData,
  } = useEditableGridData<Lot>();
  const {
    setValues,
  } = useFormikContext();

  useEffect(() => {
    setValues((values) => ({
      ...values,
      lots: rowData,
    }));
  }, [rowData, setValues]);

  return (
    <LotsGrid />
  );
};

const SummaryLotsEditPanelContent = () => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure<Draft>();
  const { stopEditing } = rfx.useActions();
  const [saveLotsAndAwardScenarios] = draft.useSaveLotsAndAwardScenarios();
  const { configureDialog: configureDisableLotsDialog, ...disableLotsDialogProps } = useConfirmDialogWithConfig();
  const {
    configureDialog: configureSaveChangesDialog,
    ...saveChangesDialogProps
  } = useConfirmDialogWithConfig<AdditionalSummaryLotsSaveChangesDialogProps>();
  const { setRowData } = useEditableGridData();

  const initialValues = {
    settings: pick(structure.settings, ['areLotsEnabled', 'awardScenarios', 'canSplitAwards']),
    lots: structure.lots,
  };

  const hasAuction = structure.stages.some(isAuctionStage);

  return (
    <>
      <Formik
        validateOnBlur
        initialValues={initialValues}
        onSubmit={async (values) => {
          const lotChanges = draft.getChanges<LotChange>({
            mapping: 'lot',
            next: values.lots,
            previous: structure.lots,
            keys: draft.lotKeys,
          });

          const lotIdsToDelete = lotChanges
            .filter((change): change is LotRemovedChange => change.type === ChangeType.LOT_REMOVED)
            .map((change: LotRemovedChange) => change.lotId);

          const allSections = Object.values(structure.sectionById);

          const sectionsWithLotIdsToDelete = allSections
            .filter(section => lotIdsToDelete.includes(first(section.lotIds)));

          const hasAddedNewLots = lotChanges.some(change => change.type === ChangeType.LOT_ADDED);

          const lotsWithSectionIds = values.lots
            .map((lot, index) => ({
              lot,
              lotIndex: index,
              sectionIds: allSections
                .filter(section => first(section.lotIds) === lot._id)
                .map(section => section._id),
            }));

          const newObsoleteLotsWithSectionIds = lotsWithSectionIds
            .filter(({ lot, sectionIds }) => lot.isObsolete && !structure.lotById[lot._id]?.isObsolete && !isEmpty(sectionIds));

          const newUnobsoleteLotsWithSectionIds = lotsWithSectionIds
            .filter(({ lot, sectionIds }) => !lot.isObsolete && structure.lotById[lot._id]?.isObsolete && !isEmpty(sectionIds));

          const unobsoleteLotsChanges = flatMap(
            newUnobsoleteLotsWithSectionIds,
            ({ sectionIds }) => draft.getUnobsoleteSectionChanges(sectionIds, structure),
          );

          const sectionsWithoutLots = allSections
            .filter(section => {
              if (!isEmpty(section.lotIds)) {
                return false;
              }

              if ([
                SectionType.LINE_ITEMS,
                SectionType.QUESTION,
                SectionType.DOCUMENT,
                SectionType.VESSEL_PRICING,
              ].includes(section.type)) {
                return true;
              }

              if (section.type === SectionType.EVALUATION) {
                return !isLinkedEvaluationSection(section);
              }

              return false;
            });

          if (
            !isEmpty(sectionsWithLotIdsToDelete) ||
            (hasAddedNewLots && !isEmpty(sectionsWithoutLots)) ||
            !isEmpty(newObsoleteLotsWithSectionIds)
          ) {
            configureSaveChangesDialog({
              action: ({ deletedLotsReplacementLotId, addedLotsLotId, lotReassignments }) => {
                const sectionChanges = [
                  // these changes can occur while drafting or revising
                  ...sectionsWithLotIdsToDelete.map(section => ({
                    type: ChangeType.SECTION_UPDATED,
                    section: {
                      _id: section._id,
                      lotIds: deletedLotsReplacementLotId ? [deletedLotsReplacementLotId] : null,
                    },
                  })),
                  // these changes can occur while drafting or revising
                  ...(hasAddedNewLots ? sectionsWithoutLots.map(section => ({
                      type: ChangeType.SECTION_UPDATED,
                      section: {
                        _id: section._id,
                        lotIds: addedLotsLotId ? [addedLotsLotId] : null,
                      },
                    })) : []),
                  // these changes can only occur while revising
                  ...(!isEmpty(newObsoleteLotsWithSectionIds) ? flatMap(
                      newObsoleteLotsWithSectionIds,
                      ({ lot, sectionIds }) => {
                        const lotReassignment: LotReassignment | undefined = lotReassignments[lot._id];

                        if (lotReassignment) {
                          const { newLotId } = lotReassignment;

                          return sectionIds.map(sectionId => ({
                            type: ChangeType.SECTION_UPDATED,
                            section: {
                              _id: sectionId,
                              lotIds: newLotId ? [newLotId] : null,
                            },
                          }));
                        } else {
                          return draft.getObsoleteSectionChanges(sectionIds, structure);
                        }
                      },
                    ) : []) as any[],
                ];

                return saveLotsAndAwardScenarios({
                  settings: values.settings,
                  lotChanges,
                  sectionChanges: [...sectionChanges, ...unobsoleteLotsChanges],
                }, {
                  onSuccess: () => stopEditing(),
                });
              },
              props: {
                // heading and body are required by the DialogProps
                // interface but we set them dynamically in the
                // SummaryLotsSaveChangesDialog component
                heading: '',
                body: null,
                sectionsWithLotIdsToDelete,
                hasAddedNewLots,
                sectionsWithoutLots,
                availableLots: values.lots,
                newObsoleteLotsWithSectionIds,
              },
            });
          } else {
            return saveLotsAndAwardScenarios({
              settings: values.settings,
              lotChanges,
              sectionChanges: unobsoleteLotsChanges,
            }, {
              onSuccess: () => stopEditing(),
            });
          }
        }}
      >
        {({ isSubmitting, dirty, isValid, resetForm, values, setFieldValue }) => (
          <Form>
            <DraftSectionLabelConfigProvider width="33%">
              <PanelPadding>
                <FieldContainer
                  name="lots"
                  label={t('request.lot_other')}
                  description={t('request.summary.lotsDescription')}
                >
                  <Box onClick={stopEvent}>
                    <SwitchFieldBase
                      name="settings.areLotsEnabled"
                      hideLabel
                      aria-label={`${t('request.lot_other')} - ${t('general.enabled')}?`}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checkedText={t('general.enabled')}
                      uncheckedText={t('general.disabled')}
                      width={42}
                      disabled={hasAuction}
                      value={values.settings.areLotsEnabled}
                      onChange={(enabled: boolean) => {
                        if (enabled) {
                          const newLot = createLot();
                          setFieldValue('settings.areLotsEnabled', true);
                          setFieldValue('settings.awardScenarios.requestLevelAward', false);
                          setFieldValue('settings.awardScenarios.lineLevelAward', false);
                          setFieldValue('settings.canSplitAwards', false);
                          if (isEmpty(values.lots)) {
                            setFieldValue('lots', [newLot]);
                            setRowData([newLot]);
                          }
                        } else {
                          // eslint-disable-next-line no-lonely-if
                          if (structure.version > -1) {
                            configureDisableLotsDialog({
                              action: () => {
                                const newRowData = structure.lots
                                  .filter(lot => lot.isLive)
                                  .map(lot => ({
                                    ...lot.liveVersion,
                                    isLive: lot.isLive,
                                    liveVersion: lot.liveVersion,
                                    isObsolete: true,
                                  }));

                                setFieldValue('settings.areLotsEnabled', false);
                                setFieldValue('settings.awardScenarios.lotLevelAward', false);
                                setFieldValue('lots', newRowData);
                                setRowData(newRowData);
                              },
                              props: {
                                heading: t('request.lots.dialog.revertChangesModal.heading'),
                                okButtonText: t('request.lots.dialog.revertChangesModal.okButtonText'),
                                okButtonVariant: 'danger',
                                cancelButtonText: t('request.lots.dialog.revertChangesModal.cancelButtonText'),
                                body: (
                                  <MessageBlock variant="warn" mt={0}>
                                    {t('request.lots.dialog.revertChangesModal.warning')}
                                  </MessageBlock>
                                ),
                              },
                            });
                          } else {
                            const hasSectionsAssignedToLots = Object.values(structure.sectionById)
                              .some(section => !isEmpty(section.lotIds));

                            configureDisableLotsDialog({
                              action: () => {
                                setFieldValue('settings.areLotsEnabled', false);
                                setFieldValue('settings.awardScenarios.lotLevelAward', false);
                                setFieldValue('lots', []);
                                setRowData([]);
                              },
                              props: {
                                heading: t('request.lots.dialog.disableLotsModal.heading'),
                                okButtonText: t('request.lots.dialog.disableLotsModal.okButtonText'),
                                okButtonVariant: 'danger',
                                cancelButtonText: t('request.lots.dialog.disableLotsModal.cancelButtonText'),
                                body: (
                                  <MessageBlock variant="warn" mt={0}>
                                    {hasSectionsAssignedToLots ? (
                                      t('request.lots.dialog.disableLotsModal.sectionsWarning')
                                    ) : (
                                      t('request.lots.dialog.disableLotsModal.lotLevelAwardWarning')
                                    )}
                                  </MessageBlock>
                                ),
                              },
                            });
                          }
                        }
                      }}
                    />
                    {hasAuction && (
                      <MessageBlock variant="info">
                        {t('request.lots.lotsCannotBeEnabled')}
                      </MessageBlock>
                    )}
                    {(
                      values.settings.areLotsEnabled ||
                      // when the request is live, don't hide lots grid so
                      // we still show obsolete lots
                      (structure.version > -1 && !isEmpty(values.lots))
                    ) && (
                      <Box mt={3}>
                        <FormikLotsGrid />
                      </Box>
                    )}
                  </Box>
                </FieldContainer>
              </PanelPadding>
              <PanelDivider />
              <PanelPadding>
                <FieldContainer
                  name="awardScenarios"
                  label={t('request.summary.awardScenario_other')}
                  description={t('request.summary.awardScenariosBuyerDescription')}
                  required
                >
                  {(values.settings.areLotsEnabled ? orderedAwardScenariosWithLots : orderedAwardScenariosWithoutLots)
                    .map((awardScenario, index, awardScenarios) => (
                      <Box
                        key={awardScenario}
                        sx={{
                          borderTop: index === 0 ? undefined : 'secondary',
                          paddingTop: index === 0 ? 0 : 3,
                          paddingBottom: index === awardScenarios.length - 1 ? 0 : 3,
                        }}
                      >
                        <CheckboxField
                          key={awardScenario}
                          name={`settings.awardScenarios.${awardScenario}`}
                          fieldLabel={
                            <AwardScenarioLabelAndDescription
                              ml="12px"
                              awardScenario={awardScenario}
                              canSplitAwards={values.settings.canSplitAwards}
                            />
                          }
                          onChange={awardScenario === 'lineLevelAward' ? (
                            (event: any) => {
                              if (!event.target.checked) {
                                setFieldValue('settings.canSplitAwards', false);
                              }
                            }
                          ) : (
                            undefined
                          )}
                        />
                        {awardScenario === 'lineLevelAward' && (
                          <Box color="subtext" ml="56px">
                            <CheckboxField
                              key={awardScenario}
                              name="settings.canSplitAwards"
                              disabled={!values.settings.awardScenarios.lineLevelAward}
                              fieldLabel={
                                <Box as="span" fontSize={1}>
                                  {t('request.awardScenarios.lineItemCanBeSplit')}
                                </Box>
                              }
                            />
                          </Box>
                        )}
                      </Box>
                    ),
                  )}
                </FieldContainer>
              </PanelPadding>
            </DraftSectionLabelConfigProvider>
            <PanelDivider />
            <PanelPadding>
              <Flex justifyContent="flex-end">
                <CancelButton onClick={callAll(resetForm, stopEditing)} mr={2} />
                <SaveButton disabled={isSubmitting || !dirty || !isValid} />
              </Flex>
            </PanelPadding>
            <LeavePageModal />
          </Form>
        )}
      </Formik>
      <Dialog style={{ content: { width: '500px' } }} {...disableLotsDialogProps} />
      {saveChangesDialogProps.isOpen && (
        <SummaryLotsSaveChangesDialog style={{ content: { width: '600px' } }} {...saveChangesDialogProps} />
      )}
    </>
  );
};

const SummaryLotsViewPanelContentWrapper = () => {
  const { settings, lots } = rfx.useStructure<Draft>();
  const showValidationErrors = draft.useShowValidationErrors();
  const { t } = useTranslation('translation');

  return (
    <Validation
      values={{
        settings,
        lots,
      }}
      schema={showValidationErrors ? (
        yup.object().shape({
          settings: yup.object().shape({
            awardScenarios: yup.object().test(
              'min-one-award-scenario',
              t('request.awardScenarios.noneSelected'),
              awardScenarios => orderedAwardScenarios.some(awardScenario => awardScenarios[awardScenario]),
            ),
          }),
          lots: settings.areLotsEnabled
            ? yup.array()
              .of(yup.object({
                name: yup.string().required(t('general.required')),
                description: yup.string().required(t('general.required')),
              }))
              .min(1, t('request.lots.noLotsAdded'))
            : yup.mixed(),
        })
      ) : (
        yup.mixed()
      )}
    >
      <SummaryLotsViewPanelContent />
    </Validation>
  );
};

const SummaryLotsViewPanelContent = () => {
  const { t } = useTranslation('translation');
  const structure = rfx.useStructure<Draft>();
  const showValidationErrors = draft.useShowValidationErrors();
  const theme = useTheme();

  const { errors } = useErrors();

  const lotsError = get(errors, 'lots');
  const awardScenariosError = get(errors, 'settings.awardScenarios');

  const selectedAwardScenarios = orderedAwardScenarios
    .filter(awardScenario => structure.settings.awardScenarios[awardScenario]);

  return (
    <DraftSectionLabelConfigProvider width="33%">
      <PanelPadding
        style={{
          backgroundColor: showValidationErrors && isString(lotsError)
            ? theme.colors.errorBackground
            : undefined,
        }}
      >
        <FieldContainer
          name="lots"
          label={t('request.lot_other')}
          description={t('request.summary.lotsDescription')}
        >
          <Box>
            <Text>
              {structure.settings.areLotsEnabled ? (
                t('general.enabled')
              ) : (
                t('general.disabled')
              )}
            </Text>
            {structure.settings.areLotsEnabled && (
              <Box mt={3}>
                {isString(lotsError) ? (
                  <ErrorMessage fontSize={2} error={lotsError} />
                ) : (
                  <LotsGrid isReadOnly />
                )}
              </Box>
            )}
          </Box>
        </FieldContainer>
      </PanelPadding>
      <PanelDivider />
      <PanelPadding
        style={{
          backgroundColor: showValidationErrors && awardScenariosError
            ? theme.colors.errorBackground
            : undefined,
        }}
      >
        <FieldContainer
          name="awardScenarios"
          label={t('request.summary.awardScenario_other')}
          description={t('request.summary.awardScenariosBuyerDescription')}
          required
        >
          {awardScenariosError ? (
            <ErrorMessage fontSize={2} error={awardScenariosError} />
          ) : isEmpty(selectedAwardScenarios) ? (
            <Text fontSize={2}>
              {t('request.awardScenarios.noneSelected')}
            </Text>
          ) : (
            selectedAwardScenarios
              .map((awardScenario, index, awardScenarios) => (
                <Box
                  key={awardScenario}
                  sx={{
                    borderTop: index === 0 ? undefined : 'secondary',
                    paddingTop: index === 0 ? 0 : 3,
                    paddingBottom: index === awardScenarios.length - 1 ? 0 : 3,
                  }}
                >
                  <AwardScenarioLabelAndDescription
                    awardScenario={awardScenario}
                    canSplitAwards={structure.settings.canSplitAwards}
                  />
                </Box>
            ))
          )}
        </FieldContainer>
      </PanelPadding>
    </DraftSectionLabelConfigProvider>
  );
};

export const SummaryLotsPanel = () => {
  const { t } = useTranslation('translation');
  const { editingPanelId } = rfx.useState();
  const { lots } = rfx.useStructure<Draft>();

  const isEditingOtherPanel = editingPanelId && editingPanelId !== panelId;
  const isEditingThisPanel = editingPanelId && editingPanelId === panelId;

  const heading = t('request.summary.lotsAndAwardScenarios');

  return (
    <DraftSectionLabelConfigProvider>
      <Panel
        as="section"
        aria-label={heading}
        mb={20}
        sx={{
          opacity: isEditingOtherPanel ? 0.5 : 1,
          boxShadow: isEditingThisPanel ? '0 0 8px 0 rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <SummaryPanelHeader
          heading={heading}
          panelId={panelId}
        />
        <PanelDivider />
        {isEditingThisPanel ? (
          <EditableGridDataProvider
            key="edit"
            rowData={lots}
            setValueInRow={setLotFieldValue}
          >
            <GridIdPrefixProvider>
              <GridMenuStateProvider>
                <SummaryLotsEditPanelContent />
              </GridMenuStateProvider>
            </GridIdPrefixProvider>
          </EditableGridDataProvider>
        ) : (
          <EditableGridDataProvider
            key="read-only"
            rowData={lots}
            enableReinitialize
          >
            <GridIdPrefixProvider>
              <GridMenuStateProvider>
                <SummaryLotsViewPanelContentWrapper />
              </GridMenuStateProvider>
            </GridIdPrefixProvider>
          </EditableGridDataProvider>
        )}
      </Panel>
    </DraftSectionLabelConfigProvider>
  );
};
