import { useMemo, useState, useEffect } from 'react';
import { Box, Heading } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { TeamMembershipsByEntity, getNameOrEmail } from '@deepstream/common/user-utils';
import * as yup from 'yup';
import { Formik, Form, useField } from 'formik';
import { compact, first, last } from 'lodash';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { MessageBlock } from '@deepstream/ui-kit/elements/MessageBlock';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '@deepstream/ui-kit/elements/popup/Modal';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { UserDetails } from '../../../UserDetails';
import { User } from '../../../types';
import { LabeledValue } from '../../../draft/LabeledValue';
import { useCompanyUsers } from './useCompanyUsers';
import { StepProgress } from '../../../ui/StepProgress';
import { SelectSoleOwnerStep } from './SelectSoleOwnerStep';

const ReviewAndConfirmStep = ({
  user,
  companyUsers,
}: {
  user: User;
  companyUsers: User[];
}) => {
  const { t } = useTranslation();

  const [{ value: questionnaireReplacementUserId }] = useField('questionnaireReplacementUserId');
  const [{ value: rfxReplacementUserId }] = useField('rfxReplacementUserId');
  const [{ value: contractReplacementUserId }] = useField('contractReplacementUserId');

  const questionnaireReplacementUser = companyUsers.find(user => user._id === questionnaireReplacementUserId);
  const rfxReplacementUser = companyUsers.find(user => user._id === rfxReplacementUserId);
  const contractReplacementUser = companyUsers.find(user => user._id === contractReplacementUserId);

  return (
    <Stack gap={3}>
      <UserDetails user={user} />
      {questionnaireReplacementUser && (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.removeSoleOwner.newOwnerForQuestionnaires')}
          value={getNameOrEmail(questionnaireReplacementUser)}
          valueProps={{}}
        />
      )}
      {rfxReplacementUser && (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.removeSoleOwner.newOwnerForRequests')}
          value={getNameOrEmail(rfxReplacementUser)}
          valueProps={{}}
        />
      )}
      {contractReplacementUser && (
        <LabeledValue
          align="left"
          label={t('teamManagement.dialog.removeSoleOwner.newOwnerForContracts')}
          value={getNameOrEmail(contractReplacementUser)}
          valueProps={{}}
        />
      )}
      <MessageBlock variant="warn" mt={0}>
        {t('teamManagement.removeUserInfo')}
      </MessageBlock>
    </Stack>
  );
};

const useSteps = ({
  teamMembershipsByEntity,
  user,
  companyUsers,
}: {
  teamMembershipsByEntity: TeamMembershipsByEntity;
  user: User;
  companyUsers: User[];
}) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const {
      request,
      contract,
      questionnaire,
    } = teamMembershipsByEntity;

    return compact([
      questionnaire.isOnlyOwner && {
        id: 'updateQuestionnaireOwnership',
        name: t('teamManagement.dialog.removeSoleOwner.updateQuestionnaireOwnership'),
        render: () => (
          <SelectSoleOwnerStep
            type="questionnaire"
            newOwnerDescription={t('teamManagement.dialog.removeSoleOwner.chooseNewQuestionnaireOwner')}
            companyUsers={companyUsers}
            user={user}
            items={questionnaire.memberships}
          />
        ),
        canProceed: values => values.questionnaireReplacementUserId,
      },
      request.isOnlyOwner && {
        id: 'updateRequestOwnership',
        name: t('teamManagement.dialog.removeSoleOwner.updateRequestOwnership'),
        render: () => (
          <SelectSoleOwnerStep
            type="request"
            newOwnerDescription={t('teamManagement.dialog.removeSoleOwner.chooseNewRequestOwner')}
            companyUsers={companyUsers}
            user={user}
            items={request.memberships}
          />
        ),
        canProceed: values => values.rfxReplacementUserId,
      },
      contract.isOnlyOwner && {
        id: 'updateContractOwnership',
        name: t('teamManagement.dialog.removeSoleOwner.updateContractOwnership'),
        render: () => (
          <SelectSoleOwnerStep
            type="contract"
            newOwnerDescription={t('teamManagement.dialog.removeSoleOwner.chooseNewContractOwner')}
            companyUsers={companyUsers}
            user={user}
            items={contract.memberships}
          />
        ),
        canProceed: values => values.contractReplacementUserId,
      },
      {
        id: 'reviewAndConfirm',
        name: t('teamManagement.dialog.removeSoleOwner.reviewAndConfirm'),
        render: () => (
          <ReviewAndConfirmStep
            user={user}
            companyUsers={companyUsers}
          />
        ),
      },
    ]);
  }, [teamMembershipsByEntity, t, companyUsers, user]);
};

export const RemoveSoleOwnerModal = ({
  teamMembershipsByEntity,
  user,
  companyId,
  close,
  removeUser,
  ...props
}: ModalProps & {
  teamMembershipsByEntity: TeamMembershipsByEntity;
  user: User;
  companyId: string;
  close: () => void;
  removeUser: (args: {
    user: User;
    questionnaireReplacementUserId?: string;
    rfxReplacementUserId?: string;
    contractReplacementUserId?: string;
  }) => void;
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const { data: companyUsers = [] } = useCompanyUsers(companyId);
  const [currentStepId, setCurrentStepId] = useState<string | null>(null);

  const steps = useSteps({
    teamMembershipsByEntity,
    user,
    companyUsers,
  });

  useEffect(
    () => {
      if (props.isOpen) {
        setCurrentStepId(steps[0]?.id);
      }
    },
    [props.isOpen, steps],
  );

  const isFirstStep = currentStepId === first(steps)?.id;
  const isLastStep = currentStepId === last(steps)?.id;
  const currentStepConfig = steps.find(config => config.id === currentStepId);
  const currentStepIndex = steps.indexOf(currentStepConfig);

  const {
    request,
    contract,
    questionnaire,
  } = teamMembershipsByEntity;

  return (
    <Modal style={{ content: { width: '500px' } }} {...props}>
      <Formik
        initialValues={{
          questionnaireReplacementUserId: undefined,
          rfxReplacementUserId: undefined,
          contractReplacementUserId: undefined,
        }}
        validationSchema={
          yup.object().shape({
            questionnaireReplacementUserId: questionnaire.isOnlyOwner
              ? yup.string().required(t('required', { ns: 'general' }))
              : yup.string(),
            rfxReplacementUserId: request.isOnlyOwner
              ? yup.string().required(t('required', { ns: 'general' }))
              : yup.string(),
            contractReplacementUserId: contract.isOnlyOwner
              ? yup.string().required(t('required', { ns: 'general' }))
              : yup.string(),
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await removeUser({ user, ...values });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, values }) => (
          <Form>
            <ModalHeader onClose={close}>
              {t('teamManagement.dialog.removeSoleOwner.heading')}
            </ModalHeader>
            <ModalBody>
              <Box minHeight="450px">
                <Heading as="h3" fontSize={4} mb="12px" fontWeight={500}>
                  {currentStepConfig?.name}
                </Heading>
                {currentStepConfig?.render()}
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="space-between">
              <StepProgress
                totalStepCount={steps.length}
                currentStepIndex={currentStepIndex}
                currentStepName={currentStepConfig?.name}
              />
              <Box>
                {!isFirstStep && (
                  <Button
                    ml={2}
                    variant="secondary"
                    key="previous"
                    type="button"
                    onClick={() => setCurrentStepId(steps[currentStepIndex - 1]?.id)}
                  >
                    {t('back', { ns: 'general' })}
                  </Button>
                )}
                {!isLastStep ? (
                  <Button
                    ml={2}
                    key="next"
                    type="button"
                    onClick={() => setCurrentStepId(steps[currentStepIndex + 1]?.id)}
                    disabled={!currentStepConfig?.canProceed(values)}
                  >
                    {t('next', { ns: 'general' })}
                  </Button>
                ) : (
                  <Button
                    ml={2}
                    key="submit"
                    type="submit"
                    variant="danger"
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    {t('teamManagement.removeUser')}
                  </Button>
                )}
              </Box>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
