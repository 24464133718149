import { useMemo } from 'react';
import { ContextType, HooksContext } from '../../useHooks';
import * as rfx from '../../rfx';
import * as draft from '../../draft/draft';
import { useExchangeCommentNotifications } from '../Exchange/ChatCell';
import { useRfqId } from '../../useRfq';
import { useAvailableBulletins } from '../../ExchangeModal/useAvailableBulletins';
import { useUpdateBulletin } from '../RequestMessages/useUpdateBulletin';

export const RequestHooksProvider = ({ children, showErrors = false }) => {
  const contextValue = useMemo(
    () => ({
      contextType: ContextType.RFX,
      useStatus: rfx.useStatus,
      useContextId: useRfqId,
      useSummary: rfx.useSummary,
      useExchangeDefById: rfx.useExchangeDefById,
      useShowValidationErrors: showErrors ? () => true : draft.useShowValidationErrors,
      useSection: rfx.useSection as any, // TODO Fix type,
      useAuction: rfx.useAuction,
      useExchanges: (options) => rfx.useExchanges(options),
      usePagePermissions: rfx.usePagePermissions,
      useBidProgress: rfx.useBidProgress,
      useCommentNotifications: useExchangeCommentNotifications,
      useRecipients: rfx.useRecipients,
      useAvailableBulletins,
      useUpdateBulletin,
      useActions: rfx.useActions,
      useESign: () => null,
      useIsSuperUserOrOwner: rfx.useIsSuperUserOrOwner,
    }),
    [showErrors],
  );

  return (
    <HooksContext.Provider value={contextValue}>
      {children}
    </HooksContext.Provider>
  );
};
