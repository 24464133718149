import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { CellProps } from 'react-table';
import { conforms, filter } from 'lodash';
import { ActionType } from '@deepstream/common/rfq-utils';
import styled from 'styled-components';
import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { NotificationAction, NotificationDomain } from '@deepstream/common/notification-utils/types';
import { useNotifications } from '../Notifications/NotificationsProvider';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useRfqId } from '../../useRfq';
import { ExchangeSnapshot } from '../../types';

const CommentIcon: React.FC<Omit<IconProps, 'icon'>> = (props) => (
  <Icon
    icon="comment"
    fontSize={4}
    regular
    fixedWidth
    {...props}
  />
);

const CommentIndicator = ({ hasComments, hasUnread }) => (
  <Flex alignItems="center" justifyContent="flex-end">
    {hasComments ? (
      <CommentIcon dot={hasUnread} />
    ) : (
      null
    )}
  </Flex>
);

export const useExchangeCommentNotifications = (exchange: ExchangeSnapshot) => {
  const rfqId = useRfqId();
  const currentCompanyId = useCurrentCompanyId({ required: true });

  const { recipientId } = exchange;
  const isRecipient = recipientId && recipientId === currentCompanyId;

  const notificationFilter = React.useMemo(() => {
    const expectedDomain = isRecipient ? NotificationDomain.RFQ_RECEIVED : NotificationDomain.RFQ_SENT;
    return conforms({
      domain: domain => domain === expectedDomain,
      action: action => action === NotificationAction.EXCHANGE_REPLY_SENT,
      to: to => to.companyId === currentCompanyId,
      meta: meta => (
        (meta.actionType === ActionType.NONE || meta.hasComment) &&
        meta.rfqId === rfqId &&
        meta.exchangeId === exchange._id &&
        (!exchange.recipientId || meta.recipientId === exchange.recipientId)
      ),
    });
  }, [isRecipient, currentCompanyId, rfqId, exchange]);

  return useNotifications(notificationFilter);
};

export const ExchangeCommentIndicatorCell: React.FC<CellProps<any>> = ({
  cell: {
    value: hasComments,
  },
  row: {
    original: exchange,
  },
}) => {
  const notifications = useExchangeCommentNotifications(exchange);

  return (
    <CommentIndicator
      hasComments={hasComments}
      hasUnread={Boolean(notifications.length)}
    />
  );
};

type NumCommentsCellProps = CellProps<ExchangeSnapshot> & {
  column: {
    showTooltip?: boolean;
  };
  useCommentNotifications?: (exchange: ExchangeSnapshot) => any[];
};

export const NumCommentsCell = ({
  cell: {
    value: numComments,
  },
  row: {
    original: exchange,
  },
  useCommentNotifications = useExchangeCommentNotifications,
}: NumCommentsCellProps) => {
  const notifications = useCommentNotifications(exchange);

  return (
    <IconText
      isIconRegular
      icon="comment"
      text={numComments}
      fontSize={2}
      iconFontSize={4}
      gap={2}
      dot={Boolean(notifications.length)}
    />
  );
};

const Dot = styled.div`
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.danger};
  right: -0.1em;
`;

export const GridNumCommentsCell = ({
  cell: {
    value: numComments,
  },
  row: {
    original: exchange,
  },
  column: {
    showTooltip,
  },
  useCommentNotifications = useExchangeCommentNotifications,
}: NumCommentsCellProps) => {
  const notifications = useCommentNotifications(exchange);

  const showDot = Boolean(notifications.length);

  return (
    <Tooltip content={showTooltip ? numComments : null}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '120px' }}
      >
        <Box>
          {showDot && (
            <Dot />
          )}
        </Box>
        <Box>
          {numComments}
        </Box>
      </Flex>
    </Tooltip>
  );
};

export const NumResponsesCell: React.FC<CellProps<ExchangeSnapshot>> = ({
  row: {
    original: exchange,
  },
}) => {
  const notifications = useExchangeCommentNotifications(exchange);
  const numComments = filter(exchange.history, 'comment').length;
  const numBulletinReferences = filter(exchange.history, { value: ActionType.REFER_TO_BULLETIN }).length;

  return (
    <>
      <IconText
        isIconRegular
        icon="comment"
        iconColor="subtext"
        text={numComments}
        fontSize={2}
        iconFontSize={4}
        gap={2}
        dot={Boolean(notifications.length)}
      />
      {!!numBulletinReferences && (
        <IconText
          icon="list-ul"
          iconColor="subtext"
          text={numBulletinReferences}
          fontSize={2}
          iconFontSize={4}
          ml={3}
          gap={2}
        />
      )}
    </>
  );
};
