import { without, isEqual, intersection } from 'lodash';
import * as React from 'react';
import { Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { usePrevious } from '@deepstream/ui-kit/hooks/usePrevious';
import { WrapperButton } from '@deepstream/ui-kit/elements/button/WrapperButton';
import { Checkbox } from '@deepstream/ui-kit/elements/input/Checkbox';
import { ExpandableList } from './ExpandableList';

type ClearButtonProps = {
  clearSelection: () => void;
  style: any;
};

const ClearButton: React.FC<ClearButtonProps> = ({ clearSelection, style, ...props }) => {
  const onClearRowKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      clearSelection();
    }
  };

  return (
    <WrapperButton
      onKeyDown={onClearRowKeyDown}
      onClick={clearSelection}
      sx={{
        padding: '5px 10px',
        color: 'text',
        width: '100%',
        textAlign: 'left',
        fontSize: 2,
        fontFamily: 'primary',

        '> i': {
          color: 'subtext',
        },

        ':hover > i': {
          color: 'primary',
        },
        ...style,
      }}
      {...props}
    />
  );
};

export type CheckboxListProps = {
  value: string[];
  items: Array<{ _id: string } & { [key: string]: any }>;
  renderItem?: (item: any) => React.ReactNode;
  onChange: (ids: string[]) => void;
  onBlur?: () => void;
  withClearButton?: boolean;
  disabledItems?: string[];
  rowStyle?: React.CSSProperties;
  expandableList?: boolean;
  preserveItemOrder?: boolean;
  CheckboxLabel?: React.FunctionComponent<any>;
};

export const CheckboxList: React.FC<CheckboxListProps> = ({
  value = [],
  items,
  renderItem,
  onChange,
  onBlur,
  withClearButton,
  disabledItems,
  rowStyle = {},
  expandableList,
  preserveItemOrder,
  CheckboxLabel,
}) => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = React.useState<string[]>(value);

  const prevValue = usePrevious(value);
  const prevSelectedIds = usePrevious(selectedIds);

  React.useEffect(
    () => {
      // Selection change is being triggered from within the component (ie: toggling checkbox via mouse)
      if (isEqual(prevValue, value) && !isEqual(value, selectedIds)) {
        if (preserveItemOrder) {
          const orderedSelectedIds = intersection(items.map(item => item._id), selectedIds);

          onChange(orderedSelectedIds);
        } else {
          onChange(selectedIds);
        }
      }

      // Selection change is being triggered by prop change via `value` prop (ie: being controlled by parent component)
      if (isEqual(prevSelectedIds, selectedIds) && !isEqual(selectedIds, value)) {
        setSelectedIds(value);
      }
    },
    [prevValue, value, prevSelectedIds, selectedIds, onChange, setSelectedIds, preserveItemOrder, items],
  );

  const handleChange = React.useCallback(
    (item: any) => {
      if (selectedIds.includes(item._id)) {
        setSelectedIds(selection => without(selection, item._id));
      } else {
        setSelectedIds(selection => selection.concat(item._id));
      }
    },
    [selectedIds, setSelectedIds],
  );

  const clearSelection = () => setSelectedIds([]);

  const renderCheckbox = item => (
    <li key={item._id}>
      <Checkbox
        label={renderItem ? renderItem(item) : item.name}
        checked={selectedIds.includes(item._id)}
        onChange={() => handleChange(item)}
        onBlur={onBlur}
        disabled={disabledItems && disabledItems.includes(item._id)}
        style={rowStyle}
        truncate
        CheckboxLabel={CheckboxLabel}
      />
    </li>
  );

  return (
    <ul style={{ margin: 0, padding: 0, listStyle: 'none', lineHeight: 1.3 }}>
      {withClearButton && !!selectedIds.length && (
        <ClearButton
          clearSelection={clearSelection}
          style={rowStyle}
        >
          <Icon icon="chevron-left" mr={2} />
          <Text as="span" fontWeight={500}>
            {t('general.clear')}
          </Text>
        </ClearButton>
      )}
      {expandableList ? (
        <ExpandableList
          rows={items}
          renderRow={renderCheckbox}
          toggleButtonStyle={rowStyle}
        />
      ) : (
        items.map(renderCheckbox)
      )}
    </ul>
  );
};
