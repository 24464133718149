import { PropertyList } from '@deepstream/ui/PropertyList';
import * as rfx from '@deepstream/ui/rfx';
import * as lotPagesLayout from '@deepstream/ui/modules/Request/Live/lotPagesLayout';
import { useMemo } from 'react';
import { orderedAwardScenarios } from '@deepstream/ui/draft/SummaryLotsPanel';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export const LotAndAwardScenarioSettings = () => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();

  const properties = useMemo(() => {
    const enabledAwardScenarios = orderedAwardScenarios
      .filter(awardScenario => structure.settings.awardScenarios[awardScenario]);

    return [
      {
        name: 'Lots',
        value: structure.settings.areLotsEnabled
          ? (
            <lotPagesLayout.OrderedListContainer>
              {structure.lots.map((lot, index) => (
                <li key={lot._id}>
                  {index} – {lot.name || 'Untitled'}
                </li>
              ))}
            </lotPagesLayout.OrderedListContainer>
          )
          : 'Disabled',
        heightAuto: true,
      },
      {
        name: 'Award scenarios',
        value: isEmpty(enabledAwardScenarios)
          ? 'None selected'
          : (
            <ul style={{ paddingInlineStart: '12px', marginBottom: 0 }}>
              {enabledAwardScenarios.map(awardScenario => (
                <li key={awardScenario}>
                  {t(`request.awardScenarios.awardScenarios.${awardScenario}.label`)}
                </li>
              ))}
            </ul>
          ),
        heightAuto: true,
      },
    ];
  }, [structure, t]);

  return (
    <PropertyList properties={properties} />
  );
};
