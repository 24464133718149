/**
 * Basic information about each report type.
 * Items with `disabled: true` aren't rendered on the 'generate-reports'
 * page but will be used to display old reports on the history page.
 */
export const reportConfigByType = {
  companies: {
    _id: 'companies',
    type: 'companies',
    name: 'Companies',
    description: 'Company data and activity (formerly ‘Platform report’)',
  },
  payingCompanyStatus: {
    _id: 'payingCompanyStatus',
    type: 'payingCompanyStatus',
    name: 'Paying company status',
    description: 'Paying company status and user role counts over time',
  },
  companyEvents: {
    _id: 'companyEvents',
    type: 'companyEvents',
    name: 'Company events',
    description: 'Company creation and paying status',
  },
  companyTeamEvents: {
    _id: 'companyTeamEvents',
    type: 'companyTeamEvents',
    name: 'Company team events',
    description: 'Users added, removed, and permission or role updates',
  },
  documentsSummary: {
    _id: 'documentsSummary',
    type: 'documentsSummary',
    name: 'Documents summary',
    description: 'Activity from the \'Documents\' section',
  },
  invites: {
    _id: 'invites',
    type: 'invites',
    name: 'Invites',
    description: 'Invites issued through the invite flow modal',
  },
  prequalificationSummary: {
    _id: 'prequalificationSummary',
    type: 'prequalificationSummary',
    name: 'Pre-qualification summary',
    description: 'Total questions answered and access requests',
  },
  requests: {
    _id: 'requests',
    type: 'requests',
    name: 'Requests',
    description: 'Request data including status',
  },
  allRequestsAuditTrail: {
    _id: 'allRequestsAuditTrail',
    type: 'allRequestsAuditTrail',
    name: 'Request audit trail',
    description: 'Audit trail events for all requests on the platform',
    disabled: true,
  },
  productAndServiceTags: {
    _id: 'productAndServiceTags',
    type: 'productAndServiceTags',
    name: 'Product and service tags',
    description: 'Count of tag usage across all companies',
  },
  newProductAndServiceTags: {
    _id: 'newProductAndServiceTags',
    type: 'newProductAndServiceTags',
    name: 'Product and service tags (new)',
    description: 'Count of new (UNSPSC) tag usage across all companies',
  },
  requestDetails: {
    _id: 'requestDetails',
    type: 'requestDetails',
    name: 'Request details',
    description: 'Counts of different sections and exchanges used across all requests',
  },
  requestEvents: {
    _id: 'requestEvents',
    type: 'requestEvents',
    name: 'Request events',
    description: 'Technical request event data',
  },
  requestSpendAndSavings: {
    _id: 'requestSpendAndSavings',
    type: 'requestSpendAndSavings',
    name: 'Request spend and savings',
    description: 'Budget, value, and savings data for requests',
  },
  requestSummary: {
    _id: 'requestSummary',
    type: 'requestSummary',
    name: 'Request summary',
    description: 'Activity stats for requests which are completed',
  },
  supplierFeedback: {
    _id: 'supplierFeedback',
    type: 'supplierFeedback',
    name: 'Supplier feedback',
    description: 'Correlates supplier users who were asked for feedback with buyer companies',
  },
  supplierRequestStatus: {
    _id: 'supplierRequestStatus',
    type: 'supplierRequestStatus',
    name: 'Supplier request status',
    description: 'Status of supplier companies across all requests ',
  },
  supportRequests: {
    _id: 'supportRequests',
    type: 'supportRequests',
    name: 'Support requests',
    description: 'Messages sent via in-app ‘Help’ button',
  },
  users: {
    _id: 'users',
    type: 'users',
    name: 'Users',
    description: 'User data and company membership',
  },
  userAccessEvents: {
    _id: 'userAccessEvents',
    type: 'userAccessEvents',
    name: 'User access events',
    description: 'User-initiated events around login, signup and verification',
  },
  userCompanyRoles: {
    _id: 'userCompanyRoles',
    type: 'userCompanyRoles',
    name: 'User company roles',
    description: 'Users and their roles at each company they belong to',
  },
  userRequestPermissions: {
    _id: 'userRequestPermissions',
    type: 'userRequestPermissions',
    name: 'User request permissions',
    description: 'User roles and permissions for each of their requests',
  },
  auctionBids: {
    _id: 'auctionBids',
    type: 'auctionBids',
    name: 'Auction bids',
    description: 'Bids placed successfully as part of an auction',
  },
  auctionSummary: {
    _id: 'auctionSummary',
    type: 'auctionSummary',
    name: 'Auction summary',
    description: 'Config and stats (incl. savings) for auctions',
  },
  stageApprovals: {
    _id: 'stageApprovals',
    type: 'stageApprovals',
    name: 'Request stage approvals',
    description: 'Stage approvals across all requests',
  },
  requestLineItemFields: {
    _id: 'requestLineItemFields',
    type: 'requestLineItemFields',
    name: 'Request line item fields',
    description: 'Line item fields and config across requests',
  },
  requestLots: {
    _id: 'requestLots',
    type: 'requestLots',
    name: 'Request lots',
    description: 'Lots added to requests and templates',
  },
  requestExchanges: {
    _id: 'requestExchanges',
    type: 'requestExchanges',
    name: 'Request exchanges',
    description: 'Exchanges added by users to requests',
  },
  requestTags: {
    _id: 'requestTags',
    type: 'requestTags',
    name: 'Request tags',
    description: 'Tags assigned to requests',
  },
  requestPages: {
    _id: 'requestPages',
    type: 'requestPages',
    name: 'Request pages',
    description: 'Pages added by users to requests',
  },
  requestStages: {
    _id: 'requestStages',
    type: 'requestStages',
    name: 'Request stages',
    description: 'Stages added to requests',
  },
  userActiveSessions: {
    _id: 'userActiveSessions',
    type: 'userActiveSessions',
    name: 'User sessions',
    description: 'Periods of general user activity',
  },
  userSessionSegments: {
    _id: 'userSessionSegments',
    type: 'userSessionSegments',
    name: 'User session segments',
    description: 'Periods of user activity within a specific feature',
  },
  integrationMarketplaceActivity: {
    _id: 'integrationMarketplaceActivity',
    type: 'integrationMarketplaceActivity',
    name: 'Integration marketplace activity',
    description: 'Events tracked on the integration marketplace page',
  },
  excelSubmitActivity: {
    _id: 'excelSubmitActivity',
    type: 'excelSubmitActivity',
    name: 'Submit via Excel activity',
    description: 'Events tracked when submitting line items via Excel',
  },
  supplierRequestFeedback: {
    _id: 'supplierRequestFeedback',
    type: 'supplierRequestFeedback',
    name: 'Supplier request feedback',
    description: 'Request satisfaction feedback emails and responses\n',
  },
};
