import { useCallback, useMemo } from 'react';
import { pick } from 'lodash';
import { Attachment, ChangeType, ExchangeType, ExchangeUpdatedChange } from '@deepstream/common/rfq-utils';
import { MutationArgs } from '../../types';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useExchange } from '../../useExchange';
import { useExchangeRefetch } from '../../rfx';
import { useChangeBulletinExchanges } from './useChangeBulletinExchanges';
import { useBulletinSection } from './useBulletinSection';
import { useRfqId } from '../../useRfq';
import { useCurrentUser } from '../../useCurrentUser';

export const useUpdateBulletin = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const exchange = useExchange();
  const refetchExchange = useExchangeRefetch();
  const [changeExchanges, mutationState] = useChangeBulletinExchanges({ isBulletinUpdate: true, refetchExchange });
  const bulletinSection = useBulletinSection();
  const rfqId = useRfqId();
  const user = useCurrentUser();

  const getUpdateChange = useCallback(
    (message: string, attachments: Attachment[]): ExchangeUpdatedChange => ({
      type: ChangeType.EXCHANGE_UPDATED,
      sectionName: bulletinSection._id,
      companyId: currentCompanyId,
      docXDef: {
        _id: exchange.def._id,
        type: ExchangeType.BULLETIN,
        message,
        attachments,
        user: pick(user, ['_id', 'name']),
      } as any,
    }),
    [bulletinSection._id, currentCompanyId, exchange.def._id, user],
  );

  const updateBulletin = useCallback(
    async (action, mutationOptions?: MutationArgs) => {
      await changeExchanges({
        rfqId,
        currentCompanyId,
        changes: [getUpdateChange(action.message, action.attachments)],
      }, mutationOptions);
    },
    [changeExchanges, rfqId, currentCompanyId, getUpdateChange],
  );

  return useMemo(
    () => [
      updateBulletin,
      mutationState,
    ] as const,
    [mutationState, updateBulletin],
  );
};
