import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { callAll } from '@deepstream/utils/callAll';
import { useAdminApi, useApi } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { useToaster } from '../../../toast';
import { MutationArgs } from '../../../types';
import { useMutation } from '../../../useMutation';
import { useQuestionnaireData } from './questionnaireUtils';
import { useAdminQuestionnaireQueryKey, useQuestionnaireQueryKey } from './useQuestionnaire';
import { useIsAppAdmin } from '../../../useIsAppAdmin';

const useMutationArgs = ({ name, onSuccess, onError, onSettled }: { name: string } & MutationArgs) => {
  const { t } = useTranslation('preQualification');
  const toaster = useToaster();
  const isAppAdmin = useIsAppAdmin();

  const questionnaireQueryKey = useQuestionnaireQueryKey();
  const adminQuestionnaireQueryKey = useAdminQuestionnaireQueryKey();
  const queryKey = isAppAdmin ? adminQuestionnaireQueryKey : questionnaireQueryKey;

  const queryClient = useQueryClient();

  return {
    mutationKey: queryKey,
    onSuccess: callAll(
      onSuccess,
      () => toaster.success(t(`toaster.${name}.success`)),
    ),
    onError: callAll(
      onError,
      () => toaster.error(t(`toaster.${name}.failed`)),
    ),
    onSettled: callAll(
      onSettled,
      () => queryClient.invalidateQueries(queryKey),
    ),
  };
};

const useQuestionnaireMutation = ({
  name,
  apiFunction,
  mutationOptions,
}: {
  name: string;
  apiFunction: any;
  mutationOptions: MutationArgs;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: questionnaireId } = useQuestionnaireData();
  const mutationArgs = useMutationArgs({ name, ...mutationOptions });

  return useMutation(
    (args = {}) => apiFunction({
      currentCompanyId,
      questionnaireId,
      ...args,
    }),
    mutationArgs,
  );
};

export const useAddTeamMember = (args: MutationArgs = {}) => {
  const api = useApi();
  const adminApi = useAdminApi();

  const isAppAdmin = useIsAppAdmin();

  return useQuestionnaireMutation({
    name: 'userAdded',
    apiFunction: isAppAdmin ? adminApi.addQuestionnaireTeamMembers : api.addQuestionnaireTeamMembers,
    mutationOptions: args,
  });
};

// Used when a buyer user adds supplier users to a "Sent" questionnaire
export const useAddRecipientTeamMembers = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireSent',
    apiFunction: api.addQuestionnaireTeamMembers,
    mutationOptions: args,
  });
};

export const useUpdateTeamMemberRoles = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'userRolesUpdated',
    apiFunction: api.updateQuestionnaireTeamMemberRoles,
    mutationOptions: args,
  });
};

export const useRemoveTeamMember = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'userRemoved',
    apiFunction: api.removeQuestionnaireTeamMember,
    mutationOptions: args,
  });
};

export const useAcceptQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireAccepted',
    apiFunction: api.acceptQuestionnaire,
    mutationOptions: args,
  });
};

export const useDeclineQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireDeclined',
    apiFunction: api.declineQuestionnaire,
    mutationOptions: args,
  });
};

export const useApproveQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireApproved',
    apiFunction: api.approveQuestionnaire,
    mutationOptions: args,
  });
};

export const useRejectQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireRejected',
    apiFunction: api.rejectQuestionnaire,
    mutationOptions: args,
  });
};

export const useReopenQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireReopened',
    apiFunction: api.reopenQuestionnaire,
    mutationOptions: args,
  });
};

export const useMarkQuestionnaireAsExpired = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireMarkedAsExpired',
    apiFunction: api.markQuestionnaireAsExpired,
    mutationOptions: args,
  });
};

export const useMarkQuestionnaireAsObsolete = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireMarkedAsObsolete',
    apiFunction: api.markQuestionnaireAsObsolete,
    mutationOptions: args,
  });
};

export const useSubmitQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireSubmitted',
    apiFunction: api.submitQuestionnaire,
    mutationOptions: args,
  });
};

export const useUpdateQuestionnaire = (args: MutationArgs = {}) => {
  const api = useApi();

  return useQuestionnaireMutation({
    name: 'questionnaireUpdated',
    apiFunction: api.updateQuestionnaire,
    mutationOptions: args,
  });
};
